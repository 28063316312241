.ToastContainer {
  z-index: 200000 !important;
}

#ToastError {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.ToastErrorHeader {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

#ToastSuccess {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.ToastSuccessHeader {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

#footer-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 64px;
  height: 6vh;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #f9f9f9;
  max-width: none; /*Overwrites bootstrap grid property*/
  z-index: 99;
}

.footer-copyright {
  flex-grow: 1;
}

.footer-feedback,
.footer-copyright,
.footer-privacy {
  padding-left: 2vw;
  font-size: min(4vw, 20px);
  /* identical to box height, or 120% */
  color: #646464;
}

#FinderContainer {
  padding-bottom: 65px;
  margin: 2%;
  margin-bottom: 60px;
  padding: 1%;
  padding-top: 1%;
  height: 100%;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  border-width: 1px;
  border-color: lightgrey;
  border-style: solid;
  border-radius: 10px;
}

#TitleText {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: calc(100% + 1.1vw);
  line-height: 8vh;
  text-align: center;
  color: #000000;
}

#MapContainer {
  width: 100%;
  height: 100%;
  min-height: 65vh;
  display: flex;
}

#VendorList {
  width: 30%;
  font-size: 70%;
  margin: 8px;
}

#FinderContainer .search,
.vendorTypeSelector {
  margin: 8px;
  margin-top: 2px;
  margin-bottom: 16px;
  width: 80%;
}

#FinderContainer .table {
  box-sizing: content-box;

  min-width: 400px;
}

#MapWrapper {
  margin: 8px;
  width: 70%;
}

.vendorListTitle {
  text-transform: capitalize;
  font-weight: bold;
}

.vendorListAddress {
  font-weight: normal;
  text-transform: capitalize;
}

.vendorListContact {
  font-weight: lighter;
}

.markerName {
  font-weight: bold;
  text-transform: capitalize;
  max-width: 150px;
}

.tableSelectedRow {
  background-color: rgb(158, 199, 174) !important;
}

#EmptyTableMessage {
  font-style: italic;
  padding: 10%;
  text-align: center;
  width: max-content;
}

#FinderContainer thead {
  text-decoration: underline;
}

.label {
  padding-top: 2px;
  text-align: left;
  font-size: 1.3rem;
}

#tableWrapper {
  max-height: 450px;
  width: 520px;
  overflow-y: scroll;
}

#VendorLocationsTable {
  height: 98%;
  width: 500px;
}

#FinderContainer .form-group {
  margin-bottom: 0px;
}
